<template>
  <div class="page-content">
    <div class="mx-1">
    </div>
    <div class="row">
      <div class="col-md-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-header">
            <h3>Companies</h3>
            <div class="row d-flex justify-content-between align-items-center pt-3">
              <div class="col-12 col-sm-12">
                <form class="search-form" @submit.prevent="searchCompany()">
                  <div class="input-group border rounded-sm">
                    <div class="input-group-prepend">
                      <div class="input-group-text border-0 rounded-sm">
                        <FeatherIcon type="search" class="icon-md cursor-pointer" />
                      </div>
                    </div>
                    <input v-model="companyName" type="text" class="form-control  border-0 rounded-sm" id="searchForm" placeholder="Search here...">
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <table class="table table-hover">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Brand Name</th>
                    <th>Address</th>
                    <th>Created At</th>
                    <th colspan="4">Stats</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(company, i) in companies" :key="i" @click="setWorkspace(company)">
                    <td>{{ company.name }}</td>
                    <td>
                      <span v-if="company.brand_name == null">Not Set</span>
                      <span v-else><b>{{ company.brand_name }}</b></span>
                    </td>
                    <td>{{ company.address }}</td>
                    <td>{{ dateConverter(company.created_at)}}</td>
                    <td class="text-capitalize">
                      <Status :online="company.status == 'active'" />
                      <span class="ml-2">{{ company.status }}</span>
                      <p class="text-muted" style="font-size: .75em">Synced a few second ago</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>

      <el-pagination
      :page-size="10"
      layout="prev, pager, next"
      :total="dataTotal"
      @current-change="changePage"
      >

      </el-pagination>
    </div>
  </div>
</template>
<style>
#btn_link {
  background:none;border: none;color: #1e5b2a;
}
#btn_link:hover {
  text-decoration: underline;
  color: #143d1c;
}
.btn-search {
  margin-left: 1em;
}
.pageBox {
  background-color: #1e5b2a;
  color: white;
  padding: 0.5em 1em;
  margin: 0.5em 1em;
  cursor: pointer;
  border: 0;
}
.pageBox:hover {
  color: white;
}
</style>
<script>

import moment from 'moment';

export default {
  beforeCreate() {
    this.moment = moment;
  },
  name: 'Company',
  metaInfo: {
    title: 'Company',
  },
  data() {
    return {
      companyName: '',
      dataSize: 0,
      dataTotal: 0,
      searchName: '',
    };
  },
  computed: {
    companies() {
      return this.$store.state.backOffice.companies;
    },
    page() {
      if (this.$route.query.page) {
        return this.$route.query.page;
      }
      return 1;
    },
  },
  async mounted() {
    const loader = this.$loading.show();
    this.$store.dispatch('company/clearActiveCompany');
    await this.$store.dispatch('backOffice/loadCompanies', { pageNumber: this.page }).then(() => {
      loader.hide();
    }).catch((err) => {
      console.log(err);
      loader.hide();
    });
    this.dataTotal = this.$store.state.backOffice.companiesSize;
    this.dataSize = Math.ceil(this.$store.state.backOffice.companiesSize / 10);
  },
  watch: {
    page(newPage) {
      const loader = this.$loading.show();
      this.$store.dispatch('backOffice/loadCompanies', { pageNumber: newPage }).then(() => {
        loader.hide();
      }).catch((err) => {
        console.log(err);
        loader.hide();
      });
    },
  },
  components: {
    Status: () => import('../components/Status.vue'),
  },
  methods: {
    dateConverter(date) {
      const createdAt = new Date(date);
      return `${createdAt.toLocaleDateString('id-ID')} ${createdAt.toLocaleTimeString('id-ID')}`;
    },
    setWorkspace(company) {
      this.$store.dispatch('company/setActiveCompany', company)
        .then(() => {
          this.$router.push('/workspaces');
        });
    },
    async searchCompany() {
      this.searchName = encodeURIComponent(this.companyName);
      const loader = this.$loading.show();
      await this.$store.dispatch('backOffice/loadCompanies', { pageNumber: this.page, companyName: this.searchName });
      this.dataTotal = this.$store.state.backOffice.companiesSize;
      this.dataSize = Math.ceil(this.$store.state.backOffice.companiesSize / 10);
      loader.hide();
    },
    async changePage(currentPage) {
      const loader = this.$loading.show();
      await this.$store.dispatch('backOffice/loadCompanies', { pageNumber: currentPage, companyName: this.companyName });
      this.dataTotal = this.$store.state.backOffice.companiesSize;
      this.dataSize = Math.ceil(this.$store.state.backOffice.companiesSize / 10);
      loader.hide();
    },
  },
};
</script>
